export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '이름',
        field: 'name',
        sort: true
    },
    {
        label: '아이디(이메일)',
        field: 'email',
        sort: true
    },
    {
        label: '소속병원',
        field: 'hospital',
        sort: true
    }
];
